import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from 'ag-grid-enterprise';
import FusionCharts from 'fusioncharts';

LicenseManager.setLicenseKey(environment.agGridLicenseKey);
if (environment.production) {
  enableProdMode();
  window.console.log = () => { }
}
(FusionCharts as any).options['license']({
  key: environment.fusionChartsKey,
  creditLabel: false,
});
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
